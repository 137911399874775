import React from 'react'
import InputForm from '../components/InputForm'
import './css/FormularioContacto.css'
import { Element } from 'react-scroll';
import BotonB from '../components/BotonB';
import ImagenContacto from '../images/ServicioCliente.png'

function FormularioContacto() {

  return (
    <Element name="Contactos" className="contendor__FormularioContacto">
      <div className="parteiz__FormularioContacto">
        <h2>Solicita más información</h2>
        <p>Dejános tus datos y un experto se pondrá en contacto contigo para asesorarte.</p>
        <div className="imagen__FormularioContacto">
          <img src={ImagenContacto} alt="imagen Formulario" />
        </div>
      </div>
      <div className="parteder__FormularioContactoForm">
        <div className='formulario__FormularioContacto'>

        <InputForm Nombre={'Nombre'} />
        <InputForm Nombre={'Correo Electronico'} />
        <InputForm Nombre={'Telefono'} />
        <div className="acepto__FormularioContacto">
          <input type="checkbox" /> Acepto la Política de Tratamiento de Datos.
          </div>
          <div className="btn__FormularioContacto">
          <BotonB Nombre={'Enviar'} />
          </div>
        </div>
      </div>
         
    </Element>
  )
}

export default FormularioContacto
