import React, {useState} from 'react';
import './css/InputForm.css';

function InputForm({ Nombre }) {
  const [input, setInput]=useState('')
  return (
    <div className="contenedor__InputForm">
      <label htmlFor="inputField">
        {Nombre}
      </label>
      <input 
      type="text" 
      value={input}
      onChange={setInput}
      id="inputField" />
    </div>
  );
}

export default InputForm;
