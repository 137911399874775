import React from 'react'
import './css/Informacion.css'
import BotonC from '../components/BotonC'
import { useInView } from 'react-intersection-observer';
import EmpresaPersona from '../images/empresa.png'

function Informacion() {

    const options = { threshold: 0.1 };

    // Separar refs para cada elemento
    const [imgRef, imgInView] = useInView(options);
    const [titleRef, titleInView] = useInView(options);
    const [title1Ref, title1InView] = useInView(options);
    const [title2Ref, title2InView] = useInView(options);
    const [textRef, textInView] = useInView(options);
    const [btnRef, btnInView] = useInView(options);

  return (
    <div className="contenedor__Informacion">

        <div className="texto__Informacion">
            <h2 ref={title1Ref} className={`texto__Informacionh2 ${title1InView ? 'visible' : ''}`}    >¡Funciona para todos!</h2>
            <h3 ref={title2Ref} className={`texto__Informacionh3 ${title2InView ? 'visible' : ''}`}   >Fácil para las personas y potente para las empresas.</h3>
            <div className="parrafos__Informacion">
                <div className="parrafo1__Inforamcion">
                    <h4 ref={titleRef} className={`parrafo1__Inforamcionh4 ${titleInView ? 'visible' : ''}`}  >Personas</h4>
                    <p ref={textRef} className={`parrafo1__Inforamcionp ${textInView ? 'visible' : ''}`}  >Firma documentos rápidamente para liberar tiempo en tus tareas más importantes. No solo se vuelve más fácil para las personas hacer negocios contigo, sino que también te verás más profesional.</p>
                    <div ref={btnRef} className={`btn__Informacion ${btnInView ? 'visible' : ''}`} >
                    <BotonC Nombre={'Mas Informacion'} Hacia={'Footer'} />
                    </div>
                    </div>
                <div className="parrafo1__Inforamcion">
                    <h4 ref={titleRef} className={`parrafo1__Inforamcionh4 ${titleInView ? 'visible' : ''}`} >Empresas</h4>
                    <p ref={textRef} className={`parrafo1__Inforamcionp ${textInView ? 'visible' : ''}`} >Concéntrate en las partes importantes de tu negocio, acelera las ventas, cierre tratos más rápido, y firma tus documentos con unos pocos clics.</p>
                    <div ref={btnRef} className={`btn__Informacion ${btnInView ? 'visible' : ''}`} >
                    <BotonC Nombre={'Mas Informacion'} Hacia={'Footer'} />
                    </div>
                    </div>
            </div>
        </div>
        <div  ref={imgRef} className={`imagen__Informacion ${imgInView ? 'visible' : ''}`} >
            <img src={EmpresaPersona} alt="Informacion" />
        </div>
    </div>
  )
}

export default Informacion