import Rutas from "./app/routes/Rutas";

function App() {
  return (
    
<Rutas/>
    
  );
}

export default App;
