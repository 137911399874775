// Ventas.js
import React from 'react';
import Vigencia from '../components/Vigencia';
import './css/Ventas.css';
import { Element } from 'react-scroll';

function Ventas() {
  return (
    <Element name="Ventas" className="contenedor__Ventas">
      <h2>Elige la solución adecuada para ti o tu empresa</h2>
      <div className="tabla__Ventas">
        <Vigencia Tiempo={'1 Semana'} Precio={'4,48'} Color={'blue'} />
        <Vigencia Tiempo={'2 Semanas'} Precio={'8,95'} Color={'blue'} />
        <Vigencia Tiempo={'1 Mes'} Precio={'10,52'} Color={'blue'} />
        <Vigencia Tiempo={'1 Año'} Precio={'19,50'} Color={'blue'} />
        <Vigencia Tiempo={'2 Años'} Precio={'30,30'} Color={'blue'} />
        <Vigencia Tiempo={'3 Años'} Precio={'42,54'} Color={'blue'} />
        <Vigencia Tiempo={'4 Años'} Precio={'53,34'} Color={'blue'} />
        <Vigencia Tiempo={'5 Años'} Precio={'62,70'} Color={'blue'}/>
      </div>
    </Element>
  );
}

export default Ventas;
