import React from 'react';
import Navbar from '../containers/Navbar';
import Home from '../containers/Home';
import Caracteristicas from '../containers/Caracteristicas';
import Informacion from '../containers/Informacion';
import Ventas from '../containers/Ventas';
import FormularioContacto from '../containers/FormularioContacto';
import PreguntasFrecuentes from '../containers/PreguntasFrecuentes';
import DatosContacto from '../containers/DatosContacto';
import Legal from '../containers/Legal';
import Footer from '../containers/Footer';
import './Layout.css';

function Layout() {
  return (
    <div className="contendor__Layout">
      <ul className="circles">
        {Array.from({ length: 20 }).map((_, index) => (
          <li key={index}></li>
        ))}
      </ul>
        <Navbar />
      <div className="main__content">
        <Home />
        <Caracteristicas />
        <Informacion />
        <Ventas />
        <FormularioContacto />
        <PreguntasFrecuentes />
        <DatosContacto />
        <Legal />
        <Footer />
      </div>
    </div>
  );
}

export default Layout;
