import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Inicio from '../pages/landing/Inicio'

function Rutas() {
  return (
    <Routes>
        <Route path='/' element={<Inicio/>}/>
    </Routes>
  )
}

export default Rutas