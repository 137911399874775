import React from 'react'
import BotonB from '../components/BotonB'
import './css/PreguntasFrecuentes.css'

function PreguntasFrecuentes() {
  return (
    <div className="contendor__PreguntasFrecuentes">
      <div className="parteiz__PreguntasFrecuentes">
        <h2>Preguntas frecuentes</h2>
        <p>Conoce las respuestas a las inquietudes más comunes sobre firmas electrónicas y digitales.</p>
      </div>
      <div className="Ver__PreguntasFrecuentes">
        <BotonB Nombre={'Ver Preguntas frecuentes'}/>
      </div>
    </div>
  )
}

export default PreguntasFrecuentes