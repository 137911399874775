import React from 'react'
import './css/Legal.css'
import { FaHandshakeSimple } from "react-icons/fa6";
import { IoIosDocument } from "react-icons/io";

function Legal() {
  return (
    <div className="contenedor__Legal">
      <div className="accesos__Legal">
        <div className="acceso__Legal">
          <div className="icono__Legal">
          <FaHandshakeSimple />
          </div>
          <div className="texto__Legal">
          Politica de tratamiento de datos
          </div>
        </div>
        <div className="acceso__Legal">
          <div className="icono__Legal">
          <IoIosDocument />
          </div>
          <div className="texto__Legal">
          Terminos y condiciones
          </div>
        </div>

      </div>
    </div>
  )
}

export default Legal