import React from 'react'
import './css/DatosContacto.css'
import { BsFillTelephoneFill } from "react-icons/bs";
import { FaMapMarkerAlt } from "react-icons/fa";
import { BiSolidMapPin } from "react-icons/bi";
import { IoMailSharp } from "react-icons/io5";
import { FaCalendarAlt } from "react-icons/fa";

function DatosContacto() {
  return (
    <div className="contenedor__DatosContacto">
      <div className="informacion__DatosContacto">
        <h4>Información de Contacto</h4>
        <div className="datos__DatosContacto">
          <div className="Icono__DatosContacto"><BsFillTelephoneFill /></div>
          <div className="infor__datosContacto">2-2222-222</div>
        </div>
        <div className="datos__DatosContacto">
          <div className="Icono__DatosContacto"><FaMapMarkerAlt /></div>
          <div className="infor__datosContacto">Av quito y la que cruza</div>
        </div>
        <div className="datos__DatosContacto">
          <div className="Icono__DatosContacto"><BiSolidMapPin /></div>
          <div className="infor__datosContacto">2-2222-222</div>
        </div>
      </div>
      <div className="informacion__DatosContacto">
        <h4>Información Comercial</h4>
        <div className="datos__DatosContacto">
          <div className="Icono__DatosContacto"><IoMailSharp /></div>
          <div className="infor__datosContacto">2-2222-222</div>
        </div>
        <div className="datos__DatosContacto">
          <div className="Icono__DatosContacto"><FaCalendarAlt /></div>
          <div className="infor__datosContacto">2-2222-222</div>
        </div>
      </div>
      <div className="informacion__DatosContacto">
        <h4>Soporte técnico</h4>
        <div className="datos__DatosContacto">
          <div className="Icono__DatosContacto"><IoMailSharp /></div>
          <div className="infor__datosContacto">2-2222-222</div>
        </div>
        <div className="datos__DatosContacto">
          <div className="Icono__DatosContacto"><FaCalendarAlt /></div>
          <div className="infor__datosContacto">2-2222-222</div>
        </div>
      </div>
      <div className="informacion__DatosContacto">
        <div className="imagen__DatosContacto">
          <img src="" alt="Logo" />
        </div>
      </div>

    </div>
  )
}

export default DatosContacto