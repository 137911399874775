import React from 'react'
import { Link } from 'react-router-dom'
import './css/BotonB.css'

function BotonB({Nombre}) {
  return (
    <Link to={'/'}>
    <div className="contenedor__BotonB">
      <button>{Nombre}</button>
    </div>
    </Link>
  )
}

export default BotonB