import React from 'react'
import './css/Vigencia.css'

function Vigencia({Tiempo, Precio, Color}) {
  return (
    <div className="contenedor__Vigencia">
        <div className="titulo__Vigencia">
            Vigencia
        </div>
        <div className="duracion__Vigencia" style={{ backgroundColor: Color }}>
            {Tiempo}
        </div>
        <div className="precio__Vigencia">
            ${Precio} <span>+ IVA</span>
        </div>
    </div>
  )
}

export default Vigencia