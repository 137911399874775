import React from 'react';
import BotonB from '../components/BotonB';
import './css/Navbar.css';
import { useInView } from 'react-intersection-observer';

function Navbar() {
  const { ref, inView } = useInView({
    triggerOnce: true, // Se aplica solo la primera vez que el elemento entra en vista
    threshold: 0.1,
  });

  return (
    <div ref={ref} className={`contenedor__Navbar ${inView ? 'visible' : ''}`}>
      <div className="logo__navbar">
        <img src="" alt="Logo Firmas" />
      </div>
      <div className="accesos__Navbar">
        <ul>
          <li className={inView ? 'visible' : ''}>Características y beneficios</li>
          <li className={inView ? 'visible' : ''}>Precio</li>
          <li className={inView ? 'visible' : ''}>Contacto</li>
        </ul>
        <div  className={`login__Navbar ${inView ? 'visible' : ''}`}  >
          <BotonB Nombre={'Acceder'} />
        </div>
      </div>
    </div>
  );
}

export default Navbar;
