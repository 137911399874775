import React from 'react';
import BotonC from '../components/BotonC';
import './css/Caracteristicas.css';
import { useInView } from 'react-intersection-observer';
import CaracteristicasImg from '../images/Mujer_Firma.png'

function Caracteristicas() {
  const options = { threshold: 0.1 };

  // Separar refs para cada elemento
  const [imgRef, imgInView] = useInView(options);
  const [titleRef, titleInView] = useInView(options);
  const [textRef, textInView] = useInView(options);
  const [btnRef, btnInView] = useInView(options);

  return (
    <div className="contendor__Caracteristicas">
      <div ref={imgRef} className={`imagen__caracteristicas ${imgInView ? 'visible' : ''}`}>
        <img src={CaracteristicasImg} alt="Imagen Caracteristicas" />
      </div>
      <div className="texto__Caracteristicas">
        <h2 ref={titleRef} className={`texto__Caracteristicash2 ${titleInView ? 'visible' : ''}`}>Agiliza tus procesos</h2>
        <p ref={textRef} className={`texto__Caracteristicasp ${textInView ? 'visible' : ''}`}>
          ¡Cansado de crear y firmar volúmenes de documentos uno por uno! Con Firmaya eso se acabó… Nuestra herramienta te permite simplificar tus procesos sin complicaciones.
        </p>
        <div ref={btnRef} className={`verPlanes__caracteristicas ${btnInView ? 'visible' : ''}`}>
          <BotonC Nombre={'Contactenos'} Hacia={'Contactos'} />
        </div>
      </div>
    </div>
  ); 
}

export default Caracteristicas;
