import React from 'react'
import { Link } from 'react-scroll';
import './css/BotonC.css'

function BotonC({Nombre, Hacia}) {
  return (
    <Link to={Hacia} smooth={true} duration={500}>
    <div className="contenedor__BotonC">
      <button>{Nombre}</button>
    </div>
    </Link>
  )
}

export default BotonC