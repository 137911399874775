import React from 'react';
import './css/Home.css';
import BotonC from '../components/BotonC';
import Homeimg from '../images/image.png'
import { useInView } from 'react-intersection-observer';

function Home() {
    const { ref, inView } = useInView({
        threshold: 0.1,
    });

    return (
        <div  className="contenedor__Home">
            <div className="texto__Home">
                <div ref={ref}  className={`parte1__Home ${inView ? 'visible' : ''}`}>
                    <h1>La evolución de la firma en tus manos.</h1>
                </div>
                <div ref={ref} className={`parte2__Home ${inView ? 'visible' : ''}`}>
                    Con Firmados tienes un conjunto sólido de funciones, donde puedes firmar todo lo que necesites de manera ágil.
                </div>
                <div ref={ref} className={`parte3__Home ${inView ? 'visible' : ''}`}>
                    <BotonC Nombre={'Ver planes y precios'} Hacia={'Ventas'} />
                </div>
            </div>
            <div ref={ref} className={`imagen__Home ${inView ? 'visible' : ''}`} >
                <img src={Homeimg} alt="Imagen persona firmados" />
            </div>
        </div>
    );
}

export default Home;
 