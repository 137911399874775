import React from 'react'
import './css/Footer.css'

function Footer() {
  return (
    <div className="contendor__Footer">
      Copyright © 2024 EntramadosEc - David Vega / Proveedor Tecnológico Autorizado
    </div>
  )
}

export default Footer